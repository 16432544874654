<template>
  <v-row align="center">
    <v-col cols="12"
           sm="6">
      <portal-target name="listHeader" />
    </v-col>
    <v-col cols="12"
           sm="6">
      <ess-text-field v-model="search"
                      :disabled="$wait.any"
                      placeholder="Search"
                      solo
                      hide-details
                      append-icon="mdi-magnify"
                      clearable />
    </v-col>

    <v-col cols="12">
      <ess-card no-action>
        <v-row v-if="loading"
               justify="center">
          <ess-loader class="mt-5" />
        </v-row>

        <ess-empty-message v-if="!loading && filteredItems.length === 0"
                           :icon="emptyIcon"
                           :icon-color="emptyIconColor"
                           :message="emptyMessage"
                           :sub-message="emptySubMessage" />
        <v-list v-if="!loading"
                color="blue-grey darken-4">
          <template v-for="(item, index) in filteredItems">
            <v-divider v-if="index !== 0 && index !== filteredItems.length"
                       :inset="insetDivider"
                       :key="`divider-${index}`" />

            <v-list-item :key="`item-${index}`"
                         @click="selectItem(item)">
              <slot v-if="avatar"
                    :item="item"
                    name="item-avatar">
                <v-list-item-avatar>
                  <v-gravatar :email="item.email"
                              size.number="40"
                              default-img="mm" />
                </v-list-item-avatar>
              </slot>

              <slot :item="item"
                    name="item-content">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </slot>

              <slot :item="item"
                    name="item-action" />
            </v-list-item>
          </template>
        </v-list>
      </ess-card>
    </v-col>
  </v-row>
</template>

<script>
import _sortBy from 'lodash/sortBy';
import { LAYOUT_COLS } from '@/config/constants';

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: Boolean,
      default: false,
    },
    insetDivider: {
      type: Boolean,
      default: false,
    },
    emptyIcon: {
      type: String,
      default: '',
    },
    emptyIconColor: {
      type: String,
      default: '',
    },
    emptyMessage: {
      type: String,
      default: undefined,
    },
    emptySubMessage: {
      type: String,
      default: undefined,
    },
    searchFilter: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      LAYOUT_COLS,
      search: '',
      selectedLayout: 0,
    };
  },

  computed: {
    filteredItems() {
      let returnValue = [];
      if (this.items.length > 0) {
        const filteredList = this.items.filter((item) => {
          if (item !== null) {
            return item[this.searchFilter].toLowerCase().includes(this.search.toLowerCase());
          }

          return false;
        });
        returnValue = _sortBy(filteredList, [this.searchFilter]);
      }
      return returnValue;
    },
  },

  methods: {
    selectItem(item) {
      this.$emit('item-click', item);
    },
  },
};
</script>
