<template>
  <ess-page-content :title="`Benchmark Scores`">
    <ess-simple-list :items="athleteBenchmarks"
                     :loading="$wait.is('athleteBenchmarksLoading')"
                     empty-icon="mdi-dumbbell"
                     empty-icon-color="blue-grey lighten-4"
                     :empty-message="`No Benchmarks` | startCase"
                     empty-sub-message=""
                     search-filter="benchmarkName"
                     @item-click="selectBenchmark($event)">
      <template #item-content="slotData">
        <v-list-item-content>
          <v-list-item-title v-if="isMobile"
                             class="text-subtitle-1 pt-0">
            {{ slotData.item.benchmarkName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-row>
              <v-col v-if="!isMobile"
                     cols="3">
                <v-row align="center"
                       class="ma-0">
                  <div class="text-h6">
                    <div>
                      {{ slotData.item.benchmarkName }}
                    </div>
                    <div>
                      <v-chip label
                              color="accent"
                              small>
                        {{ slotData.item.type | startCase }}
                      </v-chip>
                    </div>
                  </div>
                </v-row>
              </v-col>
              <v-col class="text-center">
                <div :class="{'text-subtitle-1': !isMobile, 'text-body-2': isMobile}">
                  Recent
                </div>
                <div :class="{'text-h4': !isMobile, 'text-h6': isMobile}">
                  {{ slotData.item.recent !== '' ? slotData.item.recent : '--' }} {{ slotData.item.suffix }}
                </div>
              </v-col>
              <v-col class="text-center">
                <div :class="{'text-subtitle-1': !isMobile, 'text-body-2': isMobile}">
                  Goal of the week
                </div>
                <div v-if="slotData.item.athlete"
                     :class="{'text-h4': !isMobile, 'text-h6': isMobile}">
                  {{ slotData.item.weeklyGoal ? parseFloat(slotData.item.weeklyGoal).toFixed(1) : '--' }} {{ slotData.item.suffix }}
                </div>
              </v-col>
              <v-col class="text-center">
                <div :class="{'text-subtitle-1': !isMobile, 'text-body-2': isMobile}">
                  Goal
                </div>
                <div v-if="slotData.item.athlete"
                     :class="{'text-h4': !isMobile, 'text-h6': isMobile}">
                  {{ slotData.item.goal !== '' ? slotData.item.isMultiplied ? Math.round(slotData.item.goal * slotData.item.athlete.weight.current) : slotData.item.goal : '--' }} {{ slotData.item.suffix }}
                </div>
              </v-col>
              <v-col class="text-center">
                <div :class="{'text-subtitle-1': !isMobile, 'text-body-2': isMobile}">
                  Best
                </div>
                <div :class="{'text-h4': !isMobile, 'text-h6': isMobile}">
                  {{ slotData.item.best !== '' ? slotData.item.best : '--' }} {{ slotData.item.suffix }}
                </div>
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </ess-simple-list>

    <v-dialog v-model="dialog"
              persistent
              max-width="290">
      <v-card color="blue-grey darken-2">
        <v-card-title class="headline">
          Edit Score
        </v-card-title>
        <v-card-text>
          <ess-text-field id="updateBenchmarkRecent"
                          v-model="benchmark.recent"
                          :mask="benchmark.mask"
                          :hint="`ex: ${benchmark.description} ${benchmark.suffix}`"
                          :placeholder="benchmark.description"
                          :suffix="benchmark.suffix"
                          :type="benchmark.fieldType"
                          :disabled="$wait.any"
                          label="Score"
                          autofocus
                          persistent-hint
                          return-masked-value
                          @focus="$event.target.select()"
                          @keypress.enter="submitAthleteBenchmark({benchmark})" />

          <ess-date-picker-field v-model="benchmark.scoreDate"
                                 label="Score Date"
                                 :max="today"
                                 :disabled="$wait.any"
                                 prepend-inner-icon="mdi-calendar-today"
                                 color="success"
                                 ref-name="scoreDate" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1"
                 text
                 @click="cancelSubmitScore">
            Cancel
          </v-btn>
          <v-btn :loading="$wait.is('updateBenchmarkLoading')"
                 color="green darken-1"
                 @click="submitAthleteBenchmark({benchmark})">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ess-page-content>
</template>

<script>
import moment from 'moment';
import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep';

import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';

import EssSimpleList from '@/components/core/EssSimpleList.vue';
import AthleteBenchmark from '../../models/AthleteBenchmark';

export default {
  components: {
    EssSimpleList,
  },

  props: {
    benchmarkType: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      search: '',
      dialog: false,
      today: moment(new Date()).format('YYYY-MM-DD'),
      benchmark: new AthleteBenchmark(),
      prevBenchmark: {},
    };
  },

  computed: {
    ...mapGetters({
      athlete: 'athletes/athlete',
      athleteBenchmarks: 'benchmarks/athleteBenchmarks',
      activeProfile: 'profiles/activeProfile',
    }),

    filteredBenchmarks() {
      let returnValue = [];
      if (this.athleteBenchmarks.length > 0) {
        const filteredList = this.athleteBenchmarks.filter(benchmark => (benchmark !== null
          ? benchmark.benchmarkName
            .toLowerCase()
            .includes(this.search ? this.search.toLowerCase() : '')
          : false));
        returnValue = _sortBy(filteredList, ['benchmarkName']);
      }
      return returnValue;
    },

  },

  watch: {
    benchmarkType(value) {
      if (value) {
        this.loadBenchmarks();
      }
    },
  },

  created() {
    this.loadBenchmarks();
  },

  methods: {
    ...mapActions('benchmarks', ['getAthleteBenchmarks', 'updateAthleteBenchmark']),

    ...mapWaitingActions('benchmarks', {
      getAthleteBenchmarks: 'athleteBenchmarksLoading',
      updateAthleteBenchmark: 'updateBenchmarkLoading',

    }),

    loadBenchmarks() {
      const options = {
        athleteId: this.athlete.id || this.authUser.email,
      };

      if (this.benchmarkType) {
        options.type = this.benchmarkType.toUpperCase();
      }

      this.getAthleteBenchmarks(options);
    },

    async selectBenchmark(benchmark) {
      this.benchmark = benchmark;
      this.prevBenchmark = _cloneDeep(benchmark);
      this.benchmark.scoreDate = this.today;

      this.dialog = true;
    },

    cancelSubmitScore() {
      this.benchmark.recent = _cloneDeep(this.prevBenchmark.recent);

      this.dialog = false;
    },

    async submitAthleteBenchmark(benchmark) {
      await this.updateAthleteBenchmark(benchmark);
      this.dialog = false;
    },
  },
};
</script>
